import logo from "./logo.svg";
import "./App.css";
import {
    Routes,
    Route,
    Link,
    useParams,
    Switch,
    Redirect,
} from "react-router-dom";
import RequestList from "./components/requestList";
import RequestView from "./components/requestView";
import RequestEdit from "./components/requestEdit";
import React, { useEffect, useState } from "react";
import axios from "axios";
import SiderDemo from "./components/siderDemo";

import Login from "./components/login.jsx";
import RequestNew from "./components/requestNew.jsx";
import UrlList from "./components/urlList.jsx";

// import React, {Component} from 'react';
// import Requests from './components/requests'
// class App extends Component {
// 	state = {
// 		requests: []
// 	}
//
// 	componentDidMount() {
// 		// fetch('http://jsonplaceholder.typicode.com/users')
// 	}
//       render() {
//         return (
//           <Requests requests={this.state.requests} />
//         )
//       }
// }
//
// export default App;
//
function App() {
    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<DoRequestNew />} />
                <Route path="/login" element={<DoLogin />} />
                <Route path="/urlList" element={<UrlList />} />

                <Route path="/demo" element={<DoDemo />} />
                <Route path="about" element={<About />} />

                <Route path="/admin/usr" element={<AdminUserList />} />
                <Route path="/admin/usrnw" element={<AdminUserNew />} />

                <Route path="/opn" element={<OpenList />} />
                <Route path="/opn/:openId" element={<OpenDetail />} />
                <Route path="/opn/:openId/ed" element={<OpenEdit />} />
                <Route path="/opnnw" element={<OpenNew />} />

                <Route path="/usr/:userShortId" element={<UserDetail />} />
                <Route path="/usr/:userShortId/ed" element={<UserEdit />} />

                <Route
                    path="/usr/:userShortId/rq"
                    element={<DoRequestList />}
                />
                <Route
                    path="/usr/:userShortId/rq/:reqId"
                    element={<DoRequestView />}
                />
                <Route
                    path="/usr/:userShortId/rq/:reqId/ed"
                    element={<DoRequestEdit />}
                />
                <Route
                    path="/usr/:userShortId/rqnw"
                    element={<DoRequestNew />}
                />
            </Routes>
        </div>
    );
}

function DoDemo() {
    return (
        <>
            <main>
                <SiderDemo />
            </main>
        </>
    );
}

function UserDetail() {
    let params = useParams();
    return (
        <>
            <main>
                <h2>사용자 상세</h2>
            </main>
        </>
    );
}

function UserEdit() {
    let params = useParams();
    return (
        <>
            <main>
                <h2>사용자 정보 수정</h2>
            </main>
        </>
    );
}

function AdminUserList() {
    let params = useParams();
    return (
        <>
            <main>
                <h2>사용자 리스트 - 관리자</h2>
            </main>
        </>
    );
}

function AdminUserNew() {
    let params = useParams();
    return (
        <>
            <main>
                <h2>사용자 추가 - 관리자</h2>
            </main>
        </>
    );
}

function OpenList() {
    let params = useParams();
    return (
        <>
            <main>
                <h2>티켓 오픈 리스트</h2>
            </main>
        </>
    );
}

function OpenDetail() {
    let params = useParams();
    return (
        <>
            <main>
                <h2>티켓 오픈 상세</h2>
                <p>{params.openId}</p>
            </main>
        </>
    );
}

function OpenEdit() {
    let params = useParams();
    return (
        <>
            <main>
                <h2>티켓 오픈 수정</h2>
                <p>{params.openId}</p>
            </main>
        </>
    );
}

function OpenNew() {
    let params = useParams();
    return (
        <>
            <main>
                <h2>오픈 추가</h2>
            </main>
        </>
    );
}

function DoRequestNew() {
    return (
        <>
            <main>
                <RequestNew />
            </main>
        </>
    );
}

function DoLogin() {
    return (
        <>
            <main>
                <Login />
            </main>
        </>
    );
}

function DoRequestEdit() {
    let params = useParams();
    const [data, setData] = useState({});

    useEffect(() => {
        let completed = false; //초기에는 실행해야 되기때문에 false flag 변수
        (async () => {
            const result = await axios.get(
                `/users/${params.userShortId}/requests/${params.reqId}`
            );
            if (!completed) setData(result.data.requests[0]);
        })();
        return () => {
            completed = true;
        };
    }, []); //input에 값이 변경이 되었을때 effect를 실행한다

    return (
        <>
            <main>
                <h2>요청 수정</h2>
                <RequestEdit request={data} userShortId={params.userShortId} />
            </main>
        </>
    );
}

function Home() {
    return (
        <>
            <main>
                <h2>Home</h2>
                <p></p>
            </main>
        </>
    );
}

function About() {
    return (
        <>
            <main>
                <h2>About</h2>
                <p></p>
            </main>
        </>
    );
}

function RequestListOrg() {
    let params = useParams();
    return (
        <>
            <main>
                <h2>요청 리스트</h2>
                <p>{params.userShortId}</p>
            </main>
        </>
    );
}

function Test() {
    return (
        <>
            <main>
                <h2>Who are we?</h2>
                <p>That feels like an existential question, don't you think?</p>
            </main>
            <nav>
                <Link to="/">Home</Link>
            </nav>
        </>
    );
}

function AppOrg() {
    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="about" element={<About />} />
            </Routes>
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <p>
                    Edit <code>src/App.js</code> and save to reload.
                </p>
                <a
                    className="App-link"
                    href="https://reactjs.org"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Learn React
                </a>
            </header>
        </div>
    );
}

function DoRequestView() {
    let params = useParams();
    const [data, setData] = useState({});

    useEffect(() => {
        let completed = false; //초기에는 실행해야 되기때문에 false flag 변수
        (async () => {
            const result = await axios.get(
                `/users/${params.userShortId}/requests/${params.reqId}`
            );
            if (!completed) setData(result.data.requests[0]);
        })();
        return () => {
            completed = true;
        };
    }, []); //input에 값이 변경이 되었을때 effect를 실행한다

    return (
        <>
            <main>
                <h2>요청 상세</h2>
                <RequestView request={data} />
            </main>
        </>
    );
}

function DoRequestList() {
    let params = useParams();
    const [data, setData] = useState([]);

    useEffect(() => {
        let completed = false; //초기에는 실행해야 되기때문에 false flag 변수
        (async () => {
            const result = await axios.get(
                `/users/${params.userShortId}/requests`
            );
            if (!completed) setData(result.data.requests);
        })();
        return () => {
            completed = true;
        };
    }, []); //input에 값이 변경이 되었을때 effect를 실행한다

    return (
        <>
            <main>
                <h2>요청 리스트</h2>
                <RequestList requests={data} />
            </main>
        </>
    );
}
export default App;

// import React, {Component} from 'react';
// import Requests from './components/requests'
// class App extends Component {
// 	state = {
// 		requests: []
// 	}
//
// 	componentDidMount() {
// 		// fetch('http://jsonplaceholder.typicode.com/users')
// 	}
//       render() {
//         return (
//           <Requests requests={this.state.requests} />
//         )
//       }
// }
//
// export default App;
