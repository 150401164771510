// src/components/login.jsx
import React, { useEffect, useState } from "react";

function Login() {
    const [user, setUser] = useState(null);

    useEffect(() => {
        fetch("http://localhost:4000/auth/user", {
            credentials: "include",
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.user) {
                    setUser(data.user);
                }
            })
            .catch((err) => console.error(err));
    }, []);

    const handleAppleLogin = () => {
        window.location.href = "http://localhost:4000/auth/apple";
    };

    return (
        <div style={{ margin: "50px" }}>
            {user ? (
                <div>
                    <p>
                        Logged in as: {user.email} (AppleID: {user.appleId})
                    </p>
                </div>
            ) : (
                <button onClick={handleAppleLogin}>Sign in with Apple</button>
            )}
        </div>
    );
}

export default Login;
