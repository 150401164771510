import axios from "axios";
import { Link } from "react-router-dom";
import { DownOutlined, ReloadOutlined } from "@ant-design/icons";
import "moment/locale/ko";
import locale from "antd/es/date-picker/locale/ko_KR";
import {
    PageHeader,
    Button,
    Form,
    DatePicker,
    Space,
    AutoComplete,
    Input,
    Select,
    Switch,
    Alert,
} from "antd";
import { useEffect, useState } from "react";

import { Modal } from "antd";
import { Badge, List, Spin, Typography, Divider, Descriptions } from "antd";

import { Collapse } from "antd";
const { Panel } = Collapse;

const { Paragraph, Title, Text } = Typography;
const { Option } = Select;

// const tailLayout = { wrapperCol: { offset: 8, span: 16 } };
const tailLayout = { wrapperCol: { offset: 0, span: 16 } };

// 코드 예시
const fetch = (callback) => {
    const timestamp = +new Date();

    // XML 파일을 받아오기 위한 요청
    axios
        .get("/ticketOpen.xml?rnd=" + timestamp, { responseType: "text" })
        .then((resp) => {
            // DOMParser를 사용해 XML 문서를 파싱
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(resp.data, "application/xml");

            // <TicketOpenInfo> 태그 추출
            const ticketOpenInfo =
                xmlDoc.getElementsByTagName("TicketOpenNames")[0];
            if (!ticketOpenInfo) {
                // <TicketOpenInfo> 태그가 없을 경우를 대비
                callback();
                return;
            }

            // <TicketOpenInfo> 안의 텍스트를 라인 단위로 분리
            const lines = ticketOpenInfo.textContent
                .split(/\r?\n/) // 개행 문자 기준으로 분리
                .map((line) => line.trim())
                .filter((line) => line !== "");

            // 필요한 형태(value, text)로 맵핑
            const d = lines.map((item) => ({
                value: item,
                text: item,
            }));

            // 알파벳 순으로 정렬
            d.sort((a, b) => a.value.localeCompare(b.value));

            // 테스트용 데이터 추가
            d.push({ value: "## 테스트", text: "## 테스트" });

            // 첫 번째 callback은 캐시를 새로고침하기 위함이라 가정
            callback();
            // 두 번째 callback으로 실제 데이터 전달
            callback(d);
        })
        .catch((err) => {
            console.error(err);
            // 에러 발생 시에도 callback은 한 번 호출해주어야 할 수 있음
            callback();
        });
};

// const fetch = (callback) => {
//     const timestamp = +new Date();
//     // console.log("/opens.json?rnd=" + timestamp);
//     axios.get("/opens.json?rnd=" + timestamp).then((resp) => {
//         // console.log(resp);
//
//         const d = resp.data.map((item) => ({
//             value: item.TicketOpenName,
//             text: item.TicketOpenName,
//         }));
//         // console.log(d);
//         d.sort((a, b) => {
//             // console.log(a);
//             return a.value.localeCompare(b.value);
//         });
//         d.push({ value: "## 테스트", text: "## 테스트" });
//
//         // console.log(d);
//
//         callback(); // for cache refresh
//
//         callback(d);
//     });
//
//     // const data = TicketOpens.map((item) => ({
//     //     value: item.TicketOpenName,
//     //     text: item.TicketOpenName,
//     // }));
//     // callback(data);
// };

// const TicketOpens = [];

const options = [
    { value: "10시 00분" },
    { value: "10시 30분" },
    { value: "11시 00분" },
    { value: "11시 30분" },
    { value: "12시 00분" },
    { value: "12시 30분" },
    { value: "13시 00분" },
    { value: "13시 30분" },
    { value: "14시 00분" },
    { value: "14시 30분" },
    { value: "15시 00분" },
    { value: "15시 30분" },
    { value: "16시 00분" },
    { value: "16시 30분" },
    { value: "17시 00분" },
    { value: "17시 30분" },
    { value: "18시 00분" },
    { value: "18시 30분" },
    { value: "19시 00분" },
    { value: "19시 30분" },
    { value: "20시 00분" },
    { value: "20시 30분" },
    { value: "21시 00분" },
    { value: "21시 30분" },
    { value: "22시 00분" },
    { value: "23시 00분" },
];

<DatePicker locale={locale} />;

/**
 * Order function by key.
 * @default "asc"
 * data => field to order (Object, List, Array).
 * key => the data to be order by this key.
 */
function orderListByKey(data, key, order) {
    const compareValues =
        (key, order = "asc") =>
        (elemA, elemB) => {
            if (!elemA.hasOwnProperty(key) || !elemB.hasOwnProperty(key))
                return 0;
            const comparison = elemA[key].localeCompare(elemB[key]);
            return order === "desc" ? comparison * -1 : comparison;
        };
    return data.sort(compareValues(key, order));
}

const RequestNew = ({ userShortId }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalText, setModalText] = useState("로딩중...");

    // 데이터 fetch 함수
    const [links, setLinks] = useState([]);
    const [loading, setLoading] = useState(true);
    const fetchData = async () => {
        setLoading(true);
        try {
            const timestamp = +new Date();
            const response = await axios.get("/links.json?rnd=" + timestamp);

            let links = response.data.filter((item) => "name" in item); // infos 만
            links = links.filter((item) => item.name !== "공연명");

            // // 받은 데이터를 수정
            // let modifiedData = links.map((item) => ({
            //     title: item.infos[0],
            //     href: item.infos[1],
            // }));

            // 정렬
            links.sort((a, b) => {
                // console.log(a);
                return a.name.localeCompare(b.name);
            });

            setLinks(links);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const showModal = () => {
        setModalText("\r\n로딩중...");
        const timestamp = +new Date();

        axios
            .get("/ticketOpen.xml?rnd=" + timestamp, {
                responseType: "text",
            })
            .then((resp) => {
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(
                    resp.data,
                    "application/xml"
                );

                // <TicketOpenInfo> 태그를 찾아서 CDATA 내용을 추출
                const ticketOpenInfoElement =
                    xmlDoc.getElementsByTagName("TicketOpenInfo")[0];
                if (ticketOpenInfoElement) {
                    const cdataContent =
                        ticketOpenInfoElement.textContent.trim();
                    setModalText("\r\n" + cdataContent);
                } else {
                    setModalText("\r\n<TicketOpenInfo> 태그가 없습니다.");
                }
            })
            .catch((err) => {
                console.error(err);
                setModalText("\r\n데이터 로딩 에러가 발생했습니다.");
            });

        setIsModalOpen(true);
    };

    // const showModal = () => {
    //     setModalText("\r\n로딩중...");
    //     const timestamp = +new Date();
    //
    //     axios
    //         .get("/ticketOpen.xml?rnd=" + timestamp, { responseType: "text" })
    //         .then((resp) => {
    //             // DOMParser를 사용해 XML 문서를 파싱
    //             const parser = new DOMParser();
    //             const xmlDoc = parser.parseFromString(
    //                 resp.data,
    //                 "application/xml"
    //             );
    //
    //             // <TicketOpenInfo> 태그 추출
    //             const ticketOpenInfo =
    //                 xmlDoc.getElementsByTagName("TicketOpenInfo")[0];
    //
    //             if (!ticketOpenInfo) {
    //                 // 태그가 없으면 안내 메시지
    //                 setModalText("\r\n<TicketOpenInfo> 태그가 없습니다.");
    //             } else {
    //                 // 텍스트를 라인 단위로 분리
    //                 const lines = ticketOpenInfo.textContent
    //                     .split(/\r?\n/);
    //
    //                 // 모달에 표시
    //                 setModalText("\r\n" + lines.join("\r\n"));
    //             }
    //         })
    //         .catch((err) => {
    //             console.error(err);
    //             setModalText("\r\n에러가 발생했습니다.");
    //         });
    //
    //     setIsModalOpen(true);
    // };

    // const showModal = () => {
    //     setModalText("\r\n로딩중...");
    //     const timestamp = +new Date();
    //     // console.log("/opens.json?rnd=" + timestamp);
    //     axios.get("/sch.txt?rnd=" + timestamp).then((resp) => {
    //         // console.log(resp);
    //         setModalText("\r\n" + resp.data);
    //     });
    //
    //     setIsModalOpen(true);
    // };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [openData, setOpenData] = useState([]);

    const [state, setState] = useState({ loading: false });

    const [form] = Form.useForm();

    const onReset = () => {
        form.resetFields();
    };

    function handleChange(value) {
        console.log(`selected ${value.label}`);
    }

    function onChange(date, dateString) {
        console.log(date, dateString);
        form.resetFields(["playTime"]);
    }

    function sleep(ms) {
        return new Promise((r) => setTimeout(r, ms));
    }

    const onFinish = (values) => {
        console.log("Success:", values);
        setState({ loading: true });

        {
            /* curl -X POST -k http://localhost:8090/requests -d '{"newRequest": {"userHp": "01093402046", "ticketOpenId": 1, "ticketOpenName": "더데빌 2차", "playDate": "2021.12.12", "playTime": "17시 00분", "siteLoginId": "jhch78", "siteLoginPwd": "gkswlsdn78#", "clientCnt": 4}}' */
        }
        axios
            .post(`/requests`, {
                newRequest: {
                    userHp: values.userHp.replaceAll("-", "").trim(),
                    userTicketOpenName: values.ticketOpenInfo.label,
                    playDateTimeYn: "Y",
                    playDateTime:
                        values.playDate.format("YYYY.MM.DD (ddd)") +
                        " " +
                        values.playTime,
                    siteLoginInfoYn: "Y",
                    siteLoginInfo:
                        values.siteLoginId.trim() +
                        " " +
                        values.siteLoginPwd.trim(),
                },
            })
            .catch((e) => {
                setState({ loading: false });
                console.error(e);
            })
            .then((response) => {
                setState({ loading: false });
                if (response.status === 200) {
                    Modal.success({
                        title: `성공적으로 신청되었습니다.`,
                        content: (
                            <>
                                <p>
                                    <br />
                                    {values.ticketOpenInfo.label}
                                    <br />
                                    <br />
                                    아이디 : {values.siteLoginId.trim()}
                                    <br />
                                    비밀번호: {values.siteLoginPwd.trim()}
                                    <br />
                                    <br />
                                    입력하신 비밀번호는
                                    <br />
                                    화면캡쳐해서 보관하세요.
                                </p>
                            </>
                        ),
                    });
                }
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const onFocus = (event) => {
        if (event.target.autocomplete) {
            event.target.autocomplete = "whatever";
        }
    };

    return (
        <div className="example">
            <Spin spinning={state.loading} size="large">
                <br />
                <Space
                    direction="vertical"
                    align="end"
                    size="2"
                    style={{ width: "100%", justifyContent: "center" }}
                >
                    <Title id="#Seat" level={4}>
                        좌석
                    </Title>

                    <Title level={5}>-------------</Title>
                    <List
                        locale={{ emptyText: "미리 티켓팅 신청주세요." }}
                        dataSource={links}
                        renderItem={(item) => (
                            <List.Item
                                style={{
                                    justifyContent: "flex-end", // 오른쪽 정렬
                                    border: "none", // 라인 제거
                                    padding: "0 0", // 각 항목의 패딩 조정
                                }}
                            >
                                <Title level={5} style={{ lineHeight: "1.8" }}>
                                    <Space>
                                        <a href={item.link}>{item.name}</a>
                                        <Button
                                            htmlType="button"
                                            href={item.link}
                                        >
                                            보기
                                        </Button>
                                    </Space>
                                </Title>
                            </List.Item>
                        )}
                    />

                    <Title level={5}>-------------</Title>
                </Space>
                <br />
                <PageHeader className="site-page-header" title="티켓팅 신청" />
                <div className="form-container">
                    <Form
                        form={form}
                        name="basic"
                        size="large"
                        wrapperCol={{
                            span: 24,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        colon={false}
                    >
                        {/*
                        <Form.Item
                            name="ticketOpenInfo"
                            rules={[
                                {
                                    required: true,
                                    message: "[공연]을 선택하세요!",
                                },
                            ]}
                        >
                            <Select labelInValue placeholder="공연">
                                {orderListByKey(
                                    TicketOpens,
                                    "TicketOpenName"
                                ).map((open) => (
                                    <Option value={open.TicketOpenName}>
                                        {open.TicketOpenName}
                                    </Option>
                                ))}
                                <Option value="## 테스트">## 테스트</Option>
                            </Select>
                        </Form.Item>
		    */}
                        {/*
                        <Space
                            direction="vertical"
                            align="start"
                            size="2"
                            style={{
                                marginLeft: 40,
                                width: "100%",
                                justifyContent: "center",
                            }}
                        >
                            <Text style={{ color: "#0958d9" }}>
                                8월1일자 티켓팅부터,
                            </Text>
                            <Text style={{ color: "#0958d9" }}>
                                수고비 인상됩니다. (5만원→7만원)
                            </Text>
                            <br />
                        </Space>
		    */}
                        <Form.Item
                            name="ticketOpenInfo"
                            rules={[
                                {
                                    required: true,
                                    message: "[공연]을 선택하세요!",
                                },
                            ]}
                        >
                            <Select
                                labelInValue
                                onDropdownVisibleChange={(open) => {
                                    if (open) {
                                        fetch(setOpenData);
                                    }
                                }}
                                options={(openData || []).map((d) => ({
                                    value: d.value,
                                    label: d.text,
                                }))}
                                placeholder="공연"
                            ></Select>
                        </Form.Item>
                        <Form.Item
                            name="playDate"
                            rules={[
                                {
                                    required: true,
                                    message: "[공연 날짜]을 선택하세요!",
                                },
                            ]}
                        >
                            <DatePicker
                                format="YYYY.MM.DD (ddd)"
                                onChange={onChange}
                                allowClear={false}
                                suffixIcon={<DownOutlined />}
                                locale={locale}
                                placeholder="공연 날짜"
                            />
                        </Form.Item>
                        <Form.Item
                            name="playTime"
                            rules={[
                                {
                                    required: true,
                                    message: "[공연 시간]을 선택하세요!",
                                },
                            ]}
                        >
                            <AutoComplete
                                options={options}
                                placeholder="공연 시간"
                                filterOption={(inputValue, option) =>
                                    option.value
                                        .toUpperCase()
                                        .indexOf(inputValue.toUpperCase()) !==
                                    -1
                                }
                                maxLength={7}
                            />
                        </Form.Item>
                        <Form.Item
                            name="siteLoginId"
                            dependencies={["ticketOpenInfo"]} // ticketOpenInfo를 감지
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        const ticketOpenInfo =
                                            getFieldValue("ticketOpenInfo");

                                        if (!value) {
                                            return Promise.reject(
                                                new Error(
                                                    "[아이디]를 입력하세요!"
                                                )
                                            );
                                        }

                                        if (
                                            (
                                                ticketOpenInfo?.label || ""
                                            ).startsWith("[링]")
                                        ) {
                                            const tklIdRegex =
                                                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; // 이메일주소

                                            if (!tklIdRegex.test(value)) {
                                                return Promise.reject(
                                                    new Error(
                                                        "티켓링크는 [이메일주소] 입력해주세요."
                                                    )
                                                );
                                            }
                                        }

                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <Input placeholder="아이디" />
                        </Form.Item>
                        <Form.Item
                            name="siteLoginPwd"
                            dependencies={["ticketOpenInfo"]} // ticketOpenInfo를 감지
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        const ticketOpenInfo =
                                            getFieldValue("ticketOpenInfo");

                                        if (!value) {
                                            return Promise.reject(
                                                new Error(
                                                    "[비밀번호]를 입력하세요!"
                                                )
                                            );
                                        }

                                        if (
                                            (
                                                ticketOpenInfo?.label || ""
                                            ).startsWith("[링]")
                                        ) {
                                            const tklGeneralPwdRegex =
							/^[0-9a-zA-Z!@#$%^*+=-~\-:./()<&; ]*$/; // 일반 비밀번호 형식
						    if (
							!tklGeneralPwdRegex.test(value)
						    ) {
							return Promise.reject(
							    new Error(
								"올바른 [비밀번호]를 입력하세요!"
							    )
							);
						    }

						    const tklPwdRegex =
							/^[^\s]+ [0-9]{8}$/; // 문자열 + 빈칸 + 숫자 8자리
						    if (!tklPwdRegex.test(value)) {
							return Promise.reject(
							    new Error(
								"티켓링크는 [티켓링크비밀번호 생년월일8자리] 입력해주세요. 예> 'mypassQwe! 19870231'"
							    )
							);
						    }
						} else {
						    const generalPwdRegex =
							/^[0-9a-zA-Z!@#$%^*+=-~\-:./()<&;]*$/; // 일반 비밀번호 형식
						    if (!generalPwdRegex.test(value)) {
							return Promise.reject(
							    new Error(
								"올바른 [비밀번호]를 입력하세요!"
							    )
							);
						    }
						}

						return Promise.resolve();
					    },
					}),
				    ]}
				>
				    <Input
					placeholder="비번"
					autoComplete="off"
					onFocus={onFocus}
				    />
				</Form.Item>

				<Form.Item
				    name="userHp"
				    rules={[
					{
					    pattern: new RegExp(/^[0-9+]*$/),
					    message: "숫자만 입력가능합니다!",
					},
					{
					    required: true,
					    message: "[휴대폰번호]를 입력하세요!",
					},
				    ]}
				>
				    <Input
					placeholder="연락가능 휴대폰번호"
					maxLength={15}
				    />
				</Form.Item>
				<Form.Item {...tailLayout}>
				    <Space style={{ float: "right" }}>
					<Button type="dashed" onClick={showModal}>
					    작업일정
					</Button>
					<Modal
					    style={{
						top: 20,
					    }}
					    bodyStyle={{
						overflowY: "scroll",
						maxHeight: "calc(100vh - 200px)",
					    }}
					    title="작업일정"
					    // title={
					    //     <>
					    //         <Button
					    //             type="text"
					    //             onClick={showModal}
					    //         >
					    //             작업일정
					    //         </Button>
					    //     </>
					    // }
					    visible={isModalOpen}
					    footer={null}
					    onCancel={handleCancel}
					>
					    <pre>{modalText}</pre>
					</Modal>
					<Button type="primary" htmlType="submit">
					    신청하기
					</Button>
					<Button htmlType="button" onClick={onReset}>
					    새로작성
					</Button>
				    </Space>
				</Form.Item>
				<br />
				<Collapse>
				    {/*
				    <Panel header="인터파크외 타사이트 티켓팅 가능한가요?">
					<p>
					    현재는 인터파크만 가능합니다.
					    <br />
					    예전에는 [예술의전당], [클럽발코니],
					    [예스24] 진행하였으나, 많은 예매처를
					    운영하기에는 벅차네요.
					    <br />
					    다른 예매처 가능해지면, 공지드리겠습니다.
					</p>
				    </Panel>
				    */}
				    <Panel header="✨ 여러 회차 티켓팅 가능한가요?">
					<p>
					    인터파크> 아이디별로 1개회차만, 진행됩니다.
                                    &nbsp;&nbsp;
                                    <a href="https://help.interpark.com/ticket/search?q=%EB%8C%80%EA%B8%B0%EC%97%B4">
                                        인터파크 대기열이란?
                                    </a>
                                    <br />
                                    <br />
                                    4개 회차까지 신청가능합니다.
                                </p>
                            </Panel>
                            <Panel header="할인권종 선택 불가능하죠?">
                                <p>
                                    자세한 내용(좌석/매수/할인/연석)은 문자로
                                    알려주세요.
                                    <br />
                                    실제로 작업할때, 문자메세지를 참고합니다.
                                </p>
                            </Panel>
                            <Panel header="티켓팅 당일 동시접속해도 되나요?">
                                <p>
                                    동접해도 괜찮습니다.
                                    <br />
                                    <br />
                                    다만 동일아이디로 타대리업체와 동시의뢰한
                                    경우,
                                    <br />
                                    인터파크제제(티켓취소,예매제한)가
                                    있을수있습니다.
                                </p>
                            </Panel>
                            <Panel header="두장 예매 가능할까요?">
                                <p>
                                    현실적으로 앞자리 연석은 쉽지 않으니,
                                    감안해주세요.
                                </p>
                            </Panel>
                            <Panel header="원하는 좌석 입력칸이 없어요.">
                                <p>
                                    자세한 내용(좌석/매수/할인/연석)은 문자로
                                    알려주세요.
                                    <br />
                                    실제로 작업할때, 문자메세지를 참고합니다.
                                </p>
                            </Panel>
                            <Panel header="공연 목록에 원하는 공연이 없어요.">
                                <p>원하는 공연을 문자로 알려주세요.</p>
                            </Panel>
                        </Collapse>
                    </Form>
                    <br />
                    <br />
                    <Space
                        direction="vertical"
                        align="end"
                        size="2"
                        style={{ width: "100%", justifyContent: "center" }}
                    >
                        <Title level={5}>루꼴라대리</Title>
                        <Title level={5}>
                            <a
                                href="https://twitter.com/TicketingRucola?ref_src=twsrc%5Etfw"
                                class="twitter-follow-button"
                                data-show-count="false"
                            >
                                @TicketingRucola
                            </a>
                            <script
                                async
                                src="https://platform.twitter.com/widgets.js"
                                charset="utf-8"
                            ></script>
                        </Title>
                        <Title level={5}>010-4881-3987</Title>
                    </Space>
                    <br />
                    <br />
                    <br />
                    <br />
                </div>
            </Spin>
        </div>
    );
};

export default RequestNew;
